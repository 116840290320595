import { Box, Button, IconButton } from "@mui/material";
import { IconBadge } from "components/Icons";
import CustomSnackbar from "components/ToastMessage/CustomSnackbarToast";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { getFocus, useAtom, useGet } from "state/jotai";
import { active_project, compares } from "state/store.global";
import { _project } from "state/store.projects";
import { useUnitById } from "./hooks";

function ButtonCompare({ unitId, iconOnly, label }) {
  const unit = useUnitById(unitId)
 
  const isRent = unit.isRent()
  const unitType = isRent ? "Rent" : "Sales"

  const [comps, setComps] = useAtom(compares);
  const [count, setCount] = useState("");
  const isComps = comps[unitType]?.includes(unitId)
  // State for Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Function to open Snackbar
  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // Function to close Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };


  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  useEffect(() => {
    if (comps[unitType]?.includes(unitId)) {
      setCount(comps[unitType]?.indexOf(unitId) + 1);
    } else {
      setCount("");
    }
  }, [unitId, comps[unitType]]);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      handleSnackbarOpen(isComps ? buttonsLabel?.filter(
        (btn) => btn.UILabelProperty === "ToastRemovedforComparison"
      )[0]?.Label : buttonsLabel?.filter(
        (btn) => btn.UILabelProperty === "ToastAddedtoComparison"
      )[0]?.Label);

      if (comps[unitType]?.includes(unitId)) {
        // remove from list.
        const list = comps[unitType].filter((i) => i !== unitId);
        setComps({ ...comps, [unitType]: list});
      } else {
        // add to list.
        const list = [...comps[unitType]];
        if (3 === comps[unitType].length) {
          // max 3 items, remove an item.
          list.pop();
        }
        list.push(unitId);
        setComps({ ...comps, [unitType]: list});
      }
    },
    [comps[unitType], unitId]
  );

  return (
    <>
      {iconOnly ? (
        <IconButton onClick={handleClick}>
          <IconBadge icon="compare" badge={count} isFaIcon={true} />
        </IconButton>
      ) : (
        <Button
          type="button"
          onClick={handleClick}
          color="inherit"
          startIcon={<IconBadge icon="compare" badge={count} isFaIcon={true} />}
          sx={{ paddingTop: "0px", paddingBottom: "0px" }}
        >
          <Box component="span" className="paragraph1-regular">
            {comps[unitType]?.includes(unitId) ? `${buttonsLabel?.filter(btn => btn.UILabelProperty === 'Tilføjet')[0]?.Label}` : `${buttonsLabel?.filter(btn => btn.UILabelProperty === 'Sammenlign')[0]?.Label}`}
          </Box>
        </Button>

      )}

      <CustomSnackbar message={snackbarMessage} onClose={handleSnackbarClose} open={snackbarOpen} />


    </>
  );
}

ButtonCompare.propTypes = {
  unitId: PropTypes.number.isRequired,
  iconOnly: PropTypes.bool,
};

export default ButtonCompare;
