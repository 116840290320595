import { useAtom } from "state/jotai";
import { compares as stateCompares } from "state/store.global";

const useCompares = () => {
  const [items, setItems] = useAtom(stateCompares);
  const rentCount = items?.Rent?.length || 0
  const saleCount = items?.Sales?.length || 0

  const count = rentCount + saleCount;

  const toggle = (unitId) => {
    if (isIncluded) {
      remove(unitId);
    } else {
      add(unitId);
    }
    setItems([...items, unitId]);
  };

  const add = (unitId) => {
    const list = [...items];
    if (3 === items.length) {
      // max 3 items, remove an item.
      list.pop();
    }
    list.push(unitId);
    setItems(list);
  };

  const remove = (unitId) => {
    setItems(items.filter((id) => id !== unitId));
  };

  const isIncluded = (unitId) => items.includes(unitId);

  return {
    toggle,
    add,
    remove,
    isIncluded,
    count,
  };
};

export default useCompares;
