import { fn3 }                        from 'utilities/utility.misc'
import { remove, replace }            from 'utilities/utility.filter'
import { applyfilters }               from 'utilities/utility.filter'
import { active_project }             from 'state/store.global'
import { _project }                   from 'state/store.projects'
import { useGet, setFocus, getFocus } from 'state/jotai'

export const useToggle = ({active,setFilters,Label,state}) => {
  
  const { pin, pid }       = useGet(active_project)
  const { Units, ...rest } = getFocus(_project, 'unitsraw')?.[pin]?.[pid] ?? 'err'
  const setUnits           = setFocus(_project, 'units')

  return ( ValueProperty, isSelected, isEqualTo1 ) => {
  
    // OBJECT TO ADD/REMOVE FROM the active.multiSelect array
    const NEW_REM = { property:Label, values:[...state.filter(i=>i!==ValueProperty)] }
    const NEW_ADD = { property:Label, values:[...state, ValueProperty] }
    const NEW     = isSelected ? NEW_REM : NEW_ADD
   
    // NEW OBJECT FOR activefilters state variable
    const FILTERS_REM = { ...active, multiSelect:remove (NEW, active.multiSelect) }
    const FILTERS_REP = { ...active, multiSelect:replace(NEW, active.multiSelect) }

    const data = ( isSelected && isEqualTo1 ) ? FILTERS_REM : FILTERS_REP

    // APPLY FILTERS TO UNITS STATE
    const units_ = applyfilters( Units, data )
    const units  = { ...rest, Units:units_ }

    // SET activefilters state variable
    setFilters( a => fn3(a,pin,pid,data) )
    setUnits  ( a => fn3(a,pin,pid,units) )
    
  }

}