import { useEffect }      from 'react'
import { useFetch }       from 'hooks/fetch/useFetch'
import { _bootstrap }     from 'state/store.bootstrap'
import { active_project, compares, current_language }       from 'state/store.global'
import { comparesData }   from 'state/store.global'
import { useAtom, useGet, useSet } from 'state/jotai'
import { COMPARE, API, API1, RS_PID }        from 'api/api'

export const PreloadCompares = () => {
  
  const comps   = useGet(compares).Sales?.join(',')
  const comps_   = useGet(compares).Rent?.join(',')
  
  const currentLanguage = useAtom(current_language)[0];
  
  const setData = useSet(comparesData)
  const { pin, pid }  = useGet(active_project);

  const { D } = useFetch({ url:COMPARE(API, pid,comps,currentLanguage), file:'PreloadCompares.jsx'})
  const { D: D_ } = useFetch({ url:COMPARE(API1, RS_PID[pid],comps_,currentLanguage), file:'PreloadCompares.jsx'})

  useEffect(()=>{
    if ( D || D_ ) {
      const numberOfUnits = (D?.NumberOfUnits || 0) + (D_?.NumberOfUnits || 0)
      const salesUnit = D?.Units?.length ? D?.Units : []
      const rentUnit = D_?.Units?.length ? D_?.Units : []
      const compareObject = Object.keys( D || {}).length ? { ...D } : { ...D_ }
      
      setData({ ...compareObject, NumberOfUnits: numberOfUnits, Units: [ ...salesUnit, ...rentUnit] })
    }
  },[D, D_,comps,currentLanguage])

  return null
}
