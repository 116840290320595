import styled, { keyframes } from 'styled-components/macro'
import { sb } from 'styles/styles.scrollbar'
import { BREAK_ } from 'constants'

const fadeInLeft = keyframes`
  from { opacity: 0; transform: translateX(50px); } 
  to { opacity: 1; transform: translateX(0); }
`

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 96px 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 14px;
  ${ sb.show };
  ${ BREAK_.md_dn } {
    padding:16px 16px 0;
  }
`

export const StyledTabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top:18px;
  padding-bottom:10px;

`

export const StyledTabButton = styled.button`
  margin: 0 4px 5px;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  background-color: ${({ clr, active }) => (active ? `${clr.primary}` : `${clr.primary}bf` )};
`

export const StyledGridContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`

export const StyledGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
`

export const StyledGridItem = styled.div`
  width: 50%;
  padding: 0 8px 16px;
  animation: ${fadeInLeft} 0.8s;
  @media (min-width: 992px) {
    width: 25%;
  }
  @media (min-width: 1200px) {
    width: 25%;
  }
`
export const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 6px;
`

export const Transition = styled.div`
  // height: "200px";
  // width: ${({ isDesktop }) => isDesktop ?  "auto" : "250px"}
    width: 300px;
    height: 180px;
  transition: transform 0.3s linear;
  padding: 0 8px;
  &:hover {
    // width: 308px;
    // height: 188px;
    // padding: 0 4px;
    transform: scale(calc(308/300));
    ${ BREAK_.md_dn } {
      width: 208px;
      height: 128px;
    }
  }

  p {
    margin-top: 10px;
  }

  ${ BREAK_.md_dn } {
    width: 200px;
    height: 120px;
  }

`