import { useEffect }        from 'react'
import { useFetch }         from 'hooks/fetch/useFetch'
import { setFocus, useGet } from 'state/jotai'
import { _isometry }        from 'state/store.isometry'
import { active_project, renderLayout }     from 'state/store.global'
import { preloadImage }     from 'utilities/utility.images'
import { ISO_CONFIG }       from 'api/api'
import eachOfLimit          from 'async/eachOfLimit'
import { useSearchParams } from 'react-router-dom'

export const PreloadFrames_Tiny = () => {
  const file     = 'PreloadingTiny.jsx'
  const url      = ISO_CONFIG
  const config   = useFetch({ url, file }).D
  const ready    = useGet(renderLayout)
  const setTiny  = setFocus(_isometry, 'tiny')
  const setFrame = setFocus(_isometry, 'activeframe')
  const setMode  = setFocus(_isometry, 'mode')
  const { pin, pid } = useGet(active_project);
  const [searchParams] = useSearchParams()

  const angle = searchParams.get('angle')

  useEffect(()=>{
    if ( config && ready && pid ) {
      const len = config.length
      const arr = []
      const ini = []
      const mod = []
      eachOfLimit(config, len, 
        ( val, idx, callback ) => {
          const IMG = preloadImage(val.TINY)
          const PID = val.PROJECT_ID
          
          const INI = val.FIRST_IMAGE
          arr[idx] = ({[PID]:IMG})
     
          if(pid == PID) {
            const snapper = val.IMAGE_SNAPFRAMES
            const snapIndex = snapper[(angle > 0 && angle <= snapper.length) ? angle - 1 : 0 ]
            ini[idx] = ({[PID]:snapIndex})
          }else ini[idx] = ({[PID]:INI})
          mod[idx] = ({[PID]:true})
          setTiny( arr )
          setFrame( ini )
          setMode( mod )
          IMG.onload = () => callback()
        },
        () => {
          // console.log( 'Done preloading tiny frames' )
        }
      )
    }
  },[config, ready, pid])
  return null
}