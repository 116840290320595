import { useEffect, useState } from 'react'
import { useSWRConfig }        from 'swr'
import { useFetch }            from 'hooks/fetch/useFetch'
import { usePrevious }         from 'hooks/usePrevious'
import { _project }            from 'state/store.projects'
import { setFocus, getFocus }  from 'state/jotai'
import { fn3 }                 from 'utilities/utility.misc'
import { applyfilters }        from 'utilities/utility.filter'
import { applysorting }        from 'utilities/utility.sort'
import { useTranslation }      from 'react-i18next'
import { UNITS, VERSION, API, API1, RS_PID }      from 'api/api'

export const useFetch_Units = ({ pid=null, pin=null } = {}) => {

  const { i18n:{language} } = useTranslation()

  const [ version, setVersion ]   = useState(0)
  const [ version_, setVersion_ ] = useState(0)
  const [ init, setInit ]         = useState(false)
  const [ init_, setInit_ ]       = useState(false)

  const filters = getFocus(_project, 'activefilters')?.[pin]?.[pid]

  const sorting = getFocus(_project, 'activesorting')?.[pin]?.[pid]
 
  const setUnits        = setFocus(_project, 'units')
  const settypeAllUnits = setFocus(_project, 'typeAllUnits')
  const setRaw          = setFocus(_project, 'unitsraw')
  const setCategories   = setFocus(_project, 'unitcategories')
  
  const done         = d => setVersion(d.Dataversion)
  const done_        = d => setVersion_(d.Dataversion)
  const update       = useSWRConfig().mutate
  const prevVersion  = usePrevious(version)
  const prevVersion_ = usePrevious(version_)

  const file = 'useFetch_Units.jsx'
  const sec  = 10

  const url1  = pid ? VERSION(API, pid) : null
  const url1_ = pid ? VERSION(API1, pid) : null

  const url2  = pid ? UNITS(API, pid,language) : null
  const url2_ = pid ? UNITS(API1, RS_PID[pid],language) : null

  const {     } = useFetch({ url:url1, file, sec, done })
  const {     } = useFetch({ url:url1_, file, sec, done: done_ })

  const {D,L,E}             = useFetch({ url:url2, file })
  const {D: D_,L: L_,E: E_} = useFetch({ url:url2_, file })


  useEffect(()=>{

    if ( prevVersion !== version && init !== false ) {
      update( url2_ )
    }
    if(prevVersion_ !== version_ && init_ !== false){
      update( url2_ )
    }

    if ( D && D_ && pin >= 0 ) {
      setInit(true)
      setInit_(true)
      const mergeUnit          = {...D, Units: [...D.Units, ...D_.Units]}
      const { Units, ...rest } = mergeUnit
  
      const units_f = applyfilters( Units, filters )
      const units_s = applysorting( units_f, sorting )

      const units = { ...rest, Units:units_s }
      
      const raws_s = applysorting( Units, sorting )
      const raws   = { ...rest, Units:raws_s }

      const cats  = Units.map(i=>i.Data.Boligtype.Value)
      const cats_ = ['Alle boligtyper',...cats]

      const unitcategories = [...new Set(cats_)]

          // clog( 'unitcategories', unitcategories )

      setCategories  (a => fn3(a,pin,pid,unitcategories) )
      setUnits       (a => fn3(a,pin,pid,units) )
      setRaw         (a => fn3(a,pin,pid,raws) )

    } 

  },[version,version_,D,D_,pin,pid,sorting])

  return {D,L,E}

}