import { useEffect, useState } from 'react'
import { useLocation }      from 'react-router-dom'
import { active_project, currentActiveProject, current_language, isMulti_project_active }   from 'state/store.global'
import { _bootstrap }       from 'state/store.bootstrap'
import { _project }         from 'state/store.projects'
import { getFocus, useAtom, useSet } from 'state/jotai'

export const useSetActiveProject = () => {

  const [ res, setRes ] = useState(false)
  const setMultiActive = useAtom(isMulti_project_active)[1];
  const multiActive = useAtom(isMulti_project_active)[0];

  const type     = getFocus(_bootstrap, 'type')
  const location = useLocation().pathname
  const names    = getFocus(_bootstrap, 'projectnames')
  const languages    = getFocus(_bootstrap, 'languages')
  const ids      = getFocus(_bootstrap, 'projectids')

  const [currentLanguage, setCurrentLanguage] = useAtom(current_language);
  const activeProject = useAtom(currentActiveProject)[0];

  const setActiveID  = useSet(active_project)

  useEffect(()=>{
    if ( location && type === "poly") {
      const loc      = location.split('/')
      const language = languages.filter( l => l === loc[1])[0] ?? currentLanguage
      const currentActiveProjectURL = location.split('/')[3]

      const currentProject = ['', "isometry"].includes(activeProject) ? currentActiveProjectURL : activeProject;
      
      const findActiveProject = names[language]?.filter((lan) => lan.Presentation === currentProject);
      const currentActiveProject = findActiveProject?.length ? findActiveProject?.[0] : names[language]?.[0]

      const pin =  currentActiveProject?.PIN
      const pid = currentActiveProject?.PID
      if(language) setCurrentLanguage(language);
      if(pin > 0 ) setMultiActive(true) 
      else setMultiActive(false) 
      if(!multiActive) setActiveID({ pid, pin })
      setRes(true)
    }else {
      setActiveID({ pid: ids[0], pin: 0 })
      setMultiActive(true)
      setRes(true)
    }
  },[location, currentLanguage, multiActive])

  return res

}