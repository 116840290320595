import { useState, useEffect, useCallback } from "react";
import { useAtom } from "state/jotai";
import { ComparePortal } from "components/Compare/Compare.Portal";
import { SharePortal } from "components/Share/Share.Portal";
import { BenefitPortal } from "components/HousingBenefit/HousingBenefit";
import { HousingBenefitExtended } from "components/HousingBenefit/HousingBenefitExtended";
import { MoveInPricePortal } from "components/HousingBenefit/moveInPrice";
import { DepositePortal } from "components/HousingBenefit/Deposite";
import { PrePaidRentPricePortal } from "components/HousingBenefit/PrepaidRent";
import {
  _drawer,
  active_project,
  vis_benefit_extended,
  vis_compare,
  vis_deposite,
  vis_movein_price,
  vis_prepaidrent,
} from "state/store.global";
import { vis_share } from "state/store.global";
import { vis_calculate_benefit } from "state/store.global";
import {
  API1,
  DEPOSITECALCULATIONS,
  MOVEINCALCULATIONS,
  PREPAIDCALCULATIONS,
  RS_PID,
} from "api/api";
import { useGet } from "state/jotai";
import { useActiveUnit } from "components/Unit";
import { _bootstrap } from "state/store.bootstrap";

export const Portals = () => {
  // State for managing visibility of different portals
  const [comp, setComp] = useAtom(vis_compare);
  const [share, setShare] = useAtom(vis_share);
  const [bene, setBene] = useAtom(vis_calculate_benefit);
  const [beneExtended, setBeneExtended] = useAtom(vis_benefit_extended);
  const [mP, setMP] = useAtom(vis_movein_price);
  const [deposite, setDeposite] = useAtom(vis_deposite);
  const [prePaidRent, setPrePaidRent] = useAtom(vis_prepaidrent);

  // State for managing fetched data, loading states, and errors
  const [MIPC, setMIPC] = useState(null);
  const [MIPLoading, setMIPLoading] = useState(false);
  const [MIPError, setMIPError] = useState(null);

  const [PPRC, setPPRC] = useState(null);
  const [PPRLoading, setPPRLoading] = useState(false);
  const [PPRError, setPPRError] = useState(null);

  const [DPC, setDPC] = useState(null);
  const [DPLoading, setDPLoading] = useState(false);
  const [DPError, setDPError] = useState(null);

  // Retrieve global state values using atoms
  const drawer = useAtom(_drawer)[0];
  const isDetail = drawer.detail;
  const unit = useActiveUnit();
  const { pid, pin } = useGet(active_project);
  const type = useGet(_bootstrap).type;
  const mono = type === "mono"; // Check if the project type is 'mono'

 

  
  useEffect(() => {
    // console.log(unit?.data?.UnitID,"units")
    const fetchDepositeData = async () => {
      if (unit?.data?.UnitID) {
        try {
          const DPCURL = DEPOSITECALCULATIONS(API1, RS_PID[pid],unit?.data?.UnitID);
          const response = await fetch(DPCURL);

          // if (!response.ok) {
          //   throw new Error(`Error: ${response.status} ${response.statusText}`);
          // }

          const data = await response.json();
          setDPC(data);
        } catch (error) {
          console.error("Failed to fetch DPC data:", error);
          setDPError(error.message);
        }
      }
    };
    fetchDepositeData();
  }, [unit?.data?.UnitID, RS_PID[pid], pin,mono]);

  useEffect(() => {
    const fetchMoveInPriceData = async()=>{
      if (unit?.data?.UnitID) {
        try {
          const MIPCURL = MOVEINCALCULATIONS(API1, RS_PID[pid], unit?.data?.UnitID);
          const response = await fetch(MIPCURL);

          // if (!response.ok) {
          //   throw new Error(`Error: ${response.status} ${response.statusText}`);
          // }

          const data = await response.json();
          setMIPC(data);
        } catch (error) {
          console.error("Failed to fetch DPC data:", error);
          setDPError(error.message);
        }
      }
    }

    fetchMoveInPriceData();
  }, [unit?.data?.UnitID, RS_PID[pid], pin,mono]);

  useEffect(() => {
    
    const fetchPrePaidRentData = async()=>{
      if (unit?.data?.UnitID) {
        try {
          const PPRCURL = PREPAIDCALCULATIONS(API1, RS_PID[pid], unit?.data?.UnitID);
          const response = await fetch(PPRCURL);

          // if (!response.ok) {
          //   throw new Error(`Error: ${response.status} ${response.statusText}`);
          // }

          const data = await response.json();
          setPPRC(data);
        } catch (error) {
          console.error("Failed to fetch DPC data:", error);
          setDPError(error.message);
        }
      }


    }
    fetchPrePaidRentData();
  }, [unit?.data?.UnitID, RS_PID[pid], pin,mono]);

  // Render the portals conditionally based on state
  return (
    <>
      {comp && <ComparePortal close={() => setComp(false)} />}
      {share && <SharePortal close={() => setShare(false)} />}
      {bene && <BenefitPortal close={() => setBene(false)} />}
      {mP && (
        <MoveInPricePortal
          close={() => setMP(false)}
          MIPC={MIPC}
          DPP={DPC}
          loading={MIPLoading}
          error={MIPError}
        />
      )}
      {deposite && (
        <DepositePortal
          close={() => setDeposite(false)}
          MIPC={DPC}
          loading={DPLoading}
          error={DPError}
        />
      )}
      {prePaidRent && (
        <PrePaidRentPricePortal
          close={() => setPrePaidRent(false)}
          PPRC={PPRC}
          loading={PPRLoading}
          error={PPRError}
        />
      )}
      {beneExtended && (
        <HousingBenefitExtended close={() => setBeneExtended(false)} />
      )}
    </>
  );
};
