import styled                      from 'styled-components/macro'
import { useEffect, forwardRef }   from 'react'
import { useGet } from 'state/jotai'
import { rotate_by_slider } from 'state/store.global'

export const Canvas = forwardRef(({dim,frames,frame,pid,status,config},ref ) => {
  const isIsometryRotate = useGet(rotate_by_slider)
  
  useEffect(()=>{
    const ctx = ref?.current?.getContext('2d')
    const w = dim.W
    const h = dim.H        
    const i = frames.findIndex(i=>Object.keys(i)[0] == frame)
    const img = frames?.[i]?.[frame]

    if ( ctx && img?.src ) {
      ctx.canvas.width = w
      ctx.canvas.height = h
      ctx.clearRect(0,0,w,h)
      ctx.drawImage(img,0,0,w,h)
    }

  },[ frame, pid, frames, status, dim ])

  const ready = frames?.[config?.IMAGE_NUM-1]?.[config?.LAST_IMAGE]?.src

  return ( 
    <> 
      { ready &&
        <StyledCanvas 
          w={dim.W} 
          h={dim.H} 
          ref={ref}
          isIsometryRotate={isIsometryRotate}
          />
      } 
    </> 
  )

})

const StyledCanvas = styled.canvas`
  width: ${x => x.w}px;
  height: ${x => x.h}px;
  max-width: unset;
  background: transparent;
  position: absolute;
  // filter: ${x => !x.isIsometryRotate && 'blur(3px)'}
`
