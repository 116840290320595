import styled from 'styled-components'

export const Col = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: ${({ clr }) => clr?.icons};
  padding: 10px 0px;
  gap: 8px;
`
export const Row = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: center;
  padding: 0;
  gap: 16px;
  font-size:0.875rem;
  flex-wrap: wrap;
`
export const Button = styled.button`
  border: 0;
  background-color: ${({isSelected,clr}) => isSelected ? `${clr.multi_select_active}` : `${clr.multi_select}1A` };
  color: ${({isSelected,clr}) => isSelected ? `${clr.multi_select_active_text}` : `${clr.primary_text}`};
  cursor: pointer;
  padding: 8px 16px;
  width: max-content;
  margin: 5px 0 5px 0;
  border-radius: 6px;
  // max-width: 100px;
`