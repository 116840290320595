import { useState, useEffect } from "react";
import { useAtom, get, useGet, getFocus, useFocusGet } from "state/jotai";
import { _fullscreen, active_project } from "state/store.global";
import { Fullscreen } from "components/Fullscreen/Fullscreen";
import { MaximizedGallery } from "components/MaximizedGallery/MaximizedGallery";
import { StyledContainer } from "./Gallery.style";
import { StyledTabsContainer } from "./Gallery.style";
import { StyledTabButton } from "./Gallery.style";
import { StyledGridContainer } from "./Gallery.style";
import { StyledGrid } from "./Gallery.style";
import { StyledGridItem } from "./Gallery.style";
import { StyledImg } from "./Gallery.style";
import { _project } from "state/store.projects";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { Box, IconButton, Modal } from "@mui/material";
import { Icon } from "components/Icons";
import { Slider } from '../MaximizedGallery/MaximizedGallery.Slider'
import { _bootstrap } from "state/store.bootstrap";
import { MultiProjectImages } from "./Gallary.multipropertyImages";
import { mergeGallary } from "utilities/utiliy.filterHelper";

export const Gallery = () => {
  const projectids                           = getFocus(_bootstrap, 'projectids')
  const gallery                              = get(_project, "gallery");
  const globalGallary                        = useFocusGet(_project, "gallery");
  const [isMultiProperty, setIsMultiProject] = useState(false)
  const [activeGlry, setActiveGlry]          = useState({})

  const [activeGallaryData, setActiveGallaryData] = useState([])


  let tags = gallery?.Tags ?? [];

  const [, setIsFullscr]                    = useAtom(_fullscreen);
  const [activeTag, setActiveTag]           = useState(null);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [selectedIndex, setSelectedIndex]   = useState(0);

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];


  useEffect(()=> {
    if(Object.keys(gallery || {}).length) {
      const isMultiProperty = globalGallary.filter((val) => {
        const keys = Object.keys(val ?? {})
        if(keys.length && val[keys[0]].hasOwnProperty('1') && val[keys[0]].hasOwnProperty('2')) return true
        return false
      })
      if((projectids.length > 0 && (projectids[1] !== '1000002')) || isMultiProperty.length) setIsMultiProject(true)
    }
  
  let glry = {}
  globalGallary.forEach((gg) => {
    const ids = Object.keys(gg || {})

    if(ids.length) {
      if(activeGlry.hasOwnProperty("glryPin") && activeGlry.hasOwnProperty("glryPid") && activeGlry.hasOwnProperty("glryPidIndex") && ids[0] === activeGlry.glryPid) {
          glry = mergeGallary(gg[activeGlry.glryPid][activeGlry.glryPidIndex], glry)[1]
      }else {
        ids.forEach((id) => {
          if(gg[id].hasOwnProperty('0')) {
            glry = mergeGallary(gg[id][0], glry)[0]
          }
        })
      }
    }
  }) 
  setActiveGallaryData(glry)
  setActiveTag(null)
  }, [gallery, activeGlry])

  const style = {
    position : "absolute",
    top      : "50%",
    left     : "50%",
    transform: "translate(-50%, -50%)",
    width    : 1444,
    maxWidth : { md: "64%", xs: "100%" },
    boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
    outline  : "none"
  };
  const [open, setOpen] = useState(false);
  const handleOpen      = (i) => { setOpen(true); setSelectedIndex(i); };
  const handleClose     = () => setOpen(false);

  useEffect(() => {
    const photos = activeGallaryData?.Data?.ListOfPhotos ?? [];
    setSelectedPhotos(
      photos.reduce(function (filtered, photo, i) {
        if (activeTag === null || photo.Tags.includes(activeTag)) {
          filtered.push({
            key        : i,
            url        : photo.Url,
            thumbnail  : photo.UrlThumbnail,
            description: photo.Description,
          });
        }
        return filtered;
      }, [])
    );

  }, [activeTag, activeGallaryData]);

  const handleClickImage = (i) => {
    setSelectedIndex(i);
    setIsFullscr(true);
  };

  const sliderProps = { images: globalGallary, setActiveGlry, activeGlry }
 
  return (
    <>
     { isMultiProperty && <MultiProjectImages { ...sliderProps } /> }
      {/* tags */}
      <Box sx = {{ background: isMultiProperty ? '#EFEFEF' : 'transparent'}}>
        <StyledContainer>
          <StyledGridContainer>
          <p className = "h3" style = {{ marginTop: "10px"}}>
          {
            !activeGlry.hasOwnProperty("glryPidIndex") ? ' Alle ejendomme'
            : 
              activeGlry.glryPidIndex === "1" ? "Ejerboliger"
            : 
              activeGlry.glryPidIndex === "2" ? "Lejeboliger"
            :  ""
          }
          </p>
          </StyledGridContainer>
        </StyledContainer>
      <StyledTabsContainer>
        <StyledTabButton
          clr     = {clr}
          active  = {activeTag === null}
          onClick = {() => setActiveTag(null)}
        >
          {
            buttonsLabel?.filter((btn) => btn.UILabelProperty === "Alle")[0]
              ?.Label
          }
        </StyledTabButton>
        { activeGallaryData?.Tags?.length && activeGallaryData?.Tags.map((tag, i) => {
          return (
            tag.length !== 0 && <StyledTabButton
              key     = {i}
              clr     = {clr}
              active  = {activeTag === tag}
              onClick = {() => setActiveTag(tag)}
            >
              {
                buttonsLabel?.filter((btn) => btn.UILabelProperty === tag)[0]
                  ?.Label
              }
            </StyledTabButton>
          );
        })}
      </StyledTabsContainer>
      <StyledContainer>
        {/* grid / images */}
        <StyledGridContainer>
          <Box
            sx={{
              display       : "flex",
              flexDirection : "column",
              minHeight     : "calc(100vh - 271px)",
              justifyContent: "space-between",
            }}
          >
            <StyledGrid>
              {selectedPhotos.map((photo, i) => {
                return (
                  <StyledGridItem key = {photo.key}>
                    <StyledImg
                      src     = {photo.thumbnail}
                      alt     = {photo.description}
                      onClick = {() => handleOpen(i)}
                    />
                  </StyledGridItem>
                );
              })}
            </StyledGrid>
            <PoweredBy />
          </Box>
        </StyledGridContainer>
      </StyledContainer>
      </Box>
      {/* <Fullscreen>
        <MaximizedGallery initial = {selectedIndex} images = {selectedPhotos} />
      </Fullscreen> */}
      <Modal open={open} onClose={handleClose} sx={{
        " .MuiBackdrop-root": {
          backgroundColor: `${clr.primary}bf`
        },
      }}>
        <Box sx = {style}>
          <IconButton
            onClick = {handleClose}
            size    = "small"
            sx      = {{
              zIndex  : "1301",
              position: "absolute",
              right   : 0,
              top     : -40,
              color   : "#fff",
            }}
          >
            <Icon icon = "close" fontSize = "inherit" />
          </IconButton>
          <Slider initial = {selectedIndex} images = {selectedPhotos} />
        </Box>
      </Modal>
    </>
  );
};
