import { _isometry} from './store.isometry'
import { _lowerQualityIsometry }              from 'state/store.lowerQualityIsometry'
import { _subiso } from './store.subiso'
import { getFocus, setFocus } from 'state/jotai'
import { activeProject } from 'state/jotai'
import { activeSubiso } from 'state/jotai'
import { fn3, fn4 } from 'utilities/utility.misc'

const low_iso = key => getFocus(_lowerQualityIsometry, key)


const iso   = key => getFocus(_isometry, key)
const iso_  = key => setFocus(_isometry, key)
const siso  = key => getFocus(_subiso, key)
const siso_ = key => setFocus(_subiso, key)

export const getLowIsoData = (key, sub = false, alt = false) => {
  const { pin, pid } = activeProject(low_iso(key))

  return sub
    ? low_iso(key)?.[pin]?.[pid]?.[sub] ?? alt
    :  low_iso(key)?.[pin]?.[pid] ?? alt
}

export const getIsoData = (key, sub = false, alt = false) => {
  const { pin, pid } = activeProject(iso(key))

  return sub
    ? iso(key)?.[pin]?.[pid]?.[sub] ?? alt
    :  iso(key)?.[pin]?.[pid] ?? alt
}

export const setIsoData = key => {
  const { pin, pid } = activeProject()
  const set          = iso_(key)
  return update => set(a => fn3(a, pin, pid, update))
}

export const getSubisoData = (key, sub = false, alt = false) => {
  const { pin, pid } = activeProject()
  const activesub    = activeSubiso()
  return sub
    ? siso(key)?.[pin]?.[pid]?.find(i => i.NAME == activesub)?.RES?.[sub] ?? alt
    :  siso(key)?.[pin]?.[pid]?.find(i => i.NAME == activesub)?.RES ?? alt
}

export const setSubisoData = key => {
  const { pin, pid } = activeProject()
  const activesub    = activeSubiso()
  const set          = siso_(key)
  return update => set(a => fn4(a, pin, pid, activesub, { NAME: activesub, RES: update }))
}


  // const tiny                = getFocus(_subiso, 'tiny'        )?.[pin]?.[pid]?.find(i => i.NAME == sub)?.RES.src
  // const frames              = getFocus(_subiso, 'frames'      )?.[pin]?.[pid]?.find(i => i.NAME == sub)?.RES
  // const percent             = getFocus(_subiso, 'percent'     )?.[pin]?.[pid]?.find(i => i.NAME == sub)?.RES
  // const status              = getFocus(_subiso, 'status'      )?.[pin]?.[pid]?.find(i => i.NAME == sub)?.RES
  // const config              = getFocus(_subiso, 'config'      )?.[pin]?.[pid]?.find(i => i.NAME == sub)?.RES
  // const frame               = getFocus(_subiso, 'activeframe' )?.[pin]?.[pid]?.find(i => i.NAME == sub)?.RES
  // const mode                = getFocus(_subiso, 'mode'        )?.[pin]?.[pid]?.find(i => i.NAME == sub)
  // const svg                 = getFocus(_subiso, 'overlays'    )?.[pin]?.[pid]?.find(i => i.NAME == sub)?.RES[frame]
  // const index               = getFocus(_subiso, 'snapindex'   )?.[pin]?.[pid]?.find(i => i.NAME == sub)?.RES

  // setFrames ( a => fn4(a,pin,pid,activesub,{'NAME':activesub,'RES':ARR     }))
  // setPercent( a => fn4(a,pin,pid,activesub,{'NAME':activesub,'RES':PERCENT }))
  // setStatus ( a => fn4(a,pin,pid,activesub,{'NAME':activesub,'RES':STATUS  }))