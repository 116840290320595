import { Boolean } from "./Filter.Controls.Boolean";
import { Interval } from "./Filter.Controls.Interval";
import { MultiSelect } from "./Filter.Controls.MultiSelect";
import { Dropdown } from "./Filter.Controls.Dropdown";
import { Col, Row } from "./Filter.Controls.style";
import { Icon } from "assets/Icons_";
import { _project } from "state/store.projects";
import { getProjectData } from "state/jotai.projectDataHooks";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { useEffect, useState } from "react";

const dropdownData = {
  Type: "Dropdown",
  Default: [0],
};

export const Controls = ({ active, options, setFilters, reset }) => {
  const [filterIndex, setFilterIndex] = useState(null)

  const { pin, pid } = useGet(active_project);
  const buttonsLabel =
    getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const iconLookup = getFocus(_project, "IconLookup")?.[pin]?.[pid] ?? [];

  let lookup = {};

  const PossibleValues = getProjectData("unitcategories").map((i, j) => ({
    ID: j,
    Value: !j
      ? buttonsLabel?.filter((btn) => btn?.UILabelProperty === "AlleBoligtyper")[0]
        ?.Label
      : i,
    ValueProperty: "Boligtype",
  }));

  iconLookup?.forEach((icon) => {
    lookup[icon?.Property] = icon?.Label;
  });

  dropdownData.PossibleValues = PossibleValues;

  dropdownData.Label = buttonsLabel?.filter(
    (btn) => btn?.UILabelProperty === "Boligtype"
  )[0]?.Label;

  // if (!options?.filter((i) => i.Type == "Dropdown").length > 0) {
  //   options?.push(dropdownData);
  // }
  useEffect(()=> {
    if(active?.multiSelect?.length) {
      const isTypeAvailable = active?.multiSelect.filter((m) => m?.property === "Type")

      if(isTypeAvailable.length && isTypeAvailable[0]?.values.includes("SalesTypeEjerlejlighed")) setFilterIndex(1)
      else if(isTypeAvailable.length && isTypeAvailable[0]?.values.includes("SalesTypeLejelejlighed")) setFilterIndex(2)

    }else setFilterIndex(0)

  }, [active])
  
  return (
    <>
      {filterIndex !== null && options[filterIndex]?.map(({ Type, Label, ...i }, j) => {
        const props = { ...i, active, setFilters, key: j, index: j, Label, reset };
        const icon = { icon: lookup[Label], color: clr?.icons, size: 20 };
        
        // Check if both icon and label are "boligtype", if yes, skip rendering
        if (icon.icon === "boligtype" && Label === "Boligtype") {
          return null;
        }

        // not rendering component which have 0 or null values
        if (i.hasOwnProperty("MinValue") && i.hasOwnProperty("MaxValue")) {
          if (!i.MinValue && !i.MaxValue) return null;
        } else {
          if (!i.PossibleValues.length) return null;
        }

        return (
          <Col key={j}>
            <div
              style={{
                border: "0px solid black",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: "10px",
              }}
            >
              <Icon
                clr={clr}
                {...icon}
                isFaIcon={(icon?.icon === "area" || icon?.icon === "floor" || icon?.icon === "unit") ? false : true}
                size={(icon?.icon === "area" || icon?.icon === "floor" || icon?.icon === "unit") && 16}
              />
              <div style={{ flex: "0 0 8px" }} />
              <div
                style={{
                  position: "relative",
                  color: `${clr.primary_text}`,
                  whiteSpace: "nowrap",
                }}
                className="paragraph1-regular"
              >
                {Label}
              </div>
            </div>
            <Row>
              {Type === "MultiSelect" && <MultiSelect {...props} />}
              {Type === "MultiRange" && <Interval {...props} />}
              {Type === "Boolean" && <Boolean {...props} />}
              {/* {Type === "Dropdown" && <Dropdown {...props} />} */}
            </Row>
          </Col>
        );
      })}
    </>
  );
};
