import { useEffect, useRef } from 'react';
import eachOfLimit from 'async/eachOfLimit';
import { get, useFocusSet, useGet } from 'state/jotai';
import { useFetch } from 'hooks/fetch/useFetch';
import { _isometry } from 'state/store.isometry';
import { _bootstrap } from 'state/store.bootstrap';
import { array } from 'utilities/utility.misc';
import { preloadImage } from 'utilities/utility.images';
import { ISO_FRAMES, ISO_CONFIG } from 'api/api';
import { _lowerQualityIsometry } from 'state/store.lowerQualityIsometry';

const MAX_CONCURRENT_REQUESTS = 20;
const DEBOUNCE_TIME = 1000; // Adjust debounce time as needed

export const PreloadFrames_Mono = () => {
  const type = useGet(_bootstrap).type;
  const file = 'main/Mono/MonoPreload.jsx';
  const mono = type === 'mono';
  const url = mono ? ISO_CONFIG : null;
  const config = useFetch({ url, file }).D;

  const lowQualityFrameStatus = get(_lowerQualityIsometry, 'status');
  const lowQualityFramePercent = get(_lowerQualityIsometry, 'percent');

  const setFrames = useFocusSet(_isometry, 'frames');
  const setPercent = useFocusSet(_isometry, 'percent');
  const setStatus = useFocusSet(_isometry, 'status');
  const setConfig = useFocusSet(_isometry, 'config');

  const isFetchingRef = useRef(false);
  const requestedImagesRef = useRef(new Set());

  const getNetworkRequestsCount = () => {
    const entries = performance.getEntriesByType('resource');
    return entries.filter(entry => entry.initiatorType === 'img').length;
  };

  const preloadImages = () => {
    if (lowQualityFramePercent === '100' && lowQualityFrameStatus === 'DONE' && config && mono) {
      const i = config[0];
      const PID = i.PROJECT_ID;
      const NUM = i.IMAGE_NUM;
      const INI = i.FIRST_IMAGE;
      const EXT = i.FILE_EXT;
      const SUB = i.PNG_PATH;
      const FNC = x => `${ISO_FRAMES}${SUB}/${x + INI}.${EXT}`;
      const COL = array(NUM, FNC);
      const ARR = [];
      setConfig([{ [PID]: i }]);

      if (isFetchingRef.current) return; // Avoid re-triggering if already fetching

      isFetchingRef.current = true;

      eachOfLimit(COL, MAX_CONCURRENT_REQUESTS, (val, idx, callback) => {
        if (!requestedImagesRef.current.has(val)) {
          const IMG = preloadImage(val);
          const STATUS = idx !== NUM - 1 ? 'FETCHING' : 'DONE';
          const PERCENT = (idx * (100 / NUM)).toFixed();
          ARR[idx] = { [INI + idx]: IMG };
          setPercent([{ [PID]: PERCENT }]);
          setFrames([{ [PID]: ARR }]);
          setStatus([{ [PID]: STATUS }]);
          requestedImagesRef.current.add(val);
          IMG.onload = () => callback();
        } else {
          callback(); // Skip loading if image has already been requested
        }
      }, () => {
        isFetchingRef.current = false; // Reset fetching status
      });
    }
  };

  useEffect(() => {
    const handleDebounce = () => {
        preloadImages();
    };

    const debounceTimeout = setTimeout(handleDebounce, DEBOUNCE_TIME);

    return () => clearTimeout(debounceTimeout);
  }, [mono, config, lowQualityFramePercent, lowQualityFrameStatus]);

  return null;
};
