export const applysorting = ( units , sorting ) => {
  const ObjKeys = Object.keys(sorting || {}).length

  if ( ObjKeys === 0 ?? true ) return units
  
  if ( ObjKeys === 4 ?? true ) return units.sort( (i,j) => {

      const q = sorting.key
      const r = sorting.direction
      const a = i?.Data[q]?.UnformattedValue
      const b = j?.Data[q]?.UnformattedValue

      console.log( a, b)

     // Handle empty strings or missing values

    // if (a === '' || a === null) return -1; // Empty strings/null are pushed to the end
    // if (b === '' || b === null) return -1;

      if ( r === 'descending' && a < b ) { return 1 }
      if ( r === 'descending' && b < a ) { return -1 }
      if ( r === 'ascending'  && a < b ) { return -1 }
      if ( r === 'ascending'  && b < a ) { return 1 }
  
      return 0
      
    })
}


export const initialsort = (sortorders) => {

  // console.log( sortorders )

  const defaultsort = sortorders.find( i => i.IsDefaultSortOrder )
  
  return ({
    key       : defaultsort?.OrderOn          ?? '',
    direction : defaultsort?.DefaultDirection ?? '',
    id        : defaultsort?.ID               ?? '',
    label     : defaultsort?.Label            ?? ''
  })

}