export function mergeArrays(arr1 = [], arr2  = []) {

    let a1 = JSON.parse(JSON.stringify(arr1));
    let a2 = JSON.parse(JSON.stringify(arr2));

    const merged = [];

    const map = new Map();

    // Helper function to merge objects
    function mergeObjects(obj1, obj2) {
        const obj = {
            ...obj1
        }
        if(obj.hasOwnProperty('PossibleValues') && Array.isArray(obj1.PossibleValues) && obj1.PossibleValues?.length) {
            const pv = obj.PossibleValues;
            const pv1 = JSON.stringify(obj1?.PossibleValues);

            obj2?.PossibleValues?.forEach((p) => {
                if(!pv1.includes(JSON.stringify(p))) pv.push(p)
            })

            obj.PossibleValues = pv
        } 
        if(obj.hasOwnProperty('Default')) obj.Default = [Math.min(...obj1.Default, ...obj2.Default), Math.max(...obj1.Default, ...obj2.Default)]
        
        if(obj1.hasOwnProperty('MaxValue') || obj2.hasOwnProperty('MinValue')) {
            obj.MaxValue = Math.max(obj1.MaxValue, obj2.MaxValue)
            obj.MinValue = Math.min(obj1.MinValue, obj2.MinValue)
        }

        return {
            ...obj
        };
    }

    // Process the first array
    a1.forEach(obj => {
        map.set(obj.Label, { ...obj });
    });
   
    // Process the second array
    a2.forEach(obj => {
        if (map.has(obj.Label)) {
            const existingObj = map.get(obj.Label);
            map.set(obj.Label, mergeObjects(existingObj, obj));
        } else {
            merged.push({...obj});
        }
    });
    
    // Convert map back to an array
    map.forEach(value => {
        merged.push(value);
    });

    
    arr1 = arr1.filter((arr) => arr.Label !== "Type")
    arr2 = arr2.filter((arr) => arr.Label !== "Type")

    return [merged, arr1, arr2];
}


export function mergeGallary(arr1 = {}, arr2 = {}) {

    let a1 = JSON.parse(JSON.stringify(arr1));
    let a2 = JSON.parse(JSON.stringify(arr2));

    const set = new Set();
    const merged = Object.keys( a1 || {}).length ? { ...a1 } : { ...a2 }
    const no_of_photos = (a1?.Data?.NoOfPhotos || 0) + (a2?.Data?.NoOfPhotos || 0)
    const list_of_photos = []

    a1?.Data?.ListOfPhotos?.forEach((imgObj) => list_of_photos.push(imgObj) )
    a2?.Data?.ListOfPhotos?.forEach((imgObj) => list_of_photos.push(imgObj) )

    a1?.Tags?.forEach((tag) => set.add(tag))
    a2?.Tags?.forEach((tag) => set.add(tag))

    return {
        "0": {
            ...merged,
            "Data": {
                ...merged.Data,
                "NoOfPhotos": no_of_photos,
                "ListOfPhotos": list_of_photos,
            },
            "Tags": [...set]
        },
        "1": arr1,
        "2": arr2
    }
}


export function mergeSortedArrays(arr1, arr2) {
    const merged = [];
    
    let a1 = JSON.parse(JSON.stringify(arr1));
    let a2 = JSON.parse(JSON.stringify(arr2));

    const map = new Map();

    // Helper function to merge objects
    function mergeObjects(obj) {
        return {
            ...obj
        };
    }

    // Process the first array
    a1.forEach(obj => {
        map.set(obj.Label, { ...obj });
    });
    
    // Process the second array
    a2.forEach(obj => {
        if (map.has(obj.Label)) {
            const existingObj = map.get(obj.Label);
            map.set(obj.Label, mergeObjects(existingObj));
        } else {
            merged.push({...obj});
        }
    });
  
    // Convert map back to an array
    map.forEach(value => {
        merged.push(value);
    });

    merged.sort((a, b) => a.ID - b.ID)

    return [merged, arr1, arr2];
}
