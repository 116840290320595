const  RES        = '/Gammelhavn/'
export const API  = 'https://eidodata.gammelhavn.nu/v1/'                                  // Sales API
export const API1 = 'https://9rzh9nu7e8ix1fyfhzjc8odb5xgh4tfp.pratik.estatetool.net/v1/'  // Rent API
const  BUN        = 'https://eidoisometri.b-cdn.net/'
const  EMAIL_API  = "send/mail"

export const FLOORPLAN = `${BUN}defaults/plantegning.jpg`

export const ISO_CONFIG = `${RES}data/iso`        + '.json'
export const MAP_CONFIG = `${RES}data/map`        + '.json'

export const COLORS     = `${RES}data/colors`     + '.json'
export const CONTACT    = `${RES}data/contact`    + '.json'
export const NAVLINKS   = `${RES}data/nav`        + '.json'
export const HIGHLIGHTS = `${RES}data/highlights` + '.json'
export const MAP_SVGS   = `${RES}data/map_svg`    + '.json'
export const ISO_SVGS   = `${RES}data/iso_svg`    + '.json'

export const ISO_FRAMES = `${BUN}`
export const MAP_FRAMES = `${RES}map`
export const STATICS    = `${RES}logos/`

export const BOOT = (API) => `${API}bootstrap`

export const IP = `${API}getip`

const BF1 = '/housingbenefits?GrossIncome='
const BF2 = '&SiteID='

export const PROJECT              = ( ext_API, PID, LNG='da' )              => PID ? `${ext_API}projects/${PID}?Language=${LNG}`                            : null
export const PRJ                  = ( ext_API, PID )                        => PID ? `${ext_API}projects/${PID}`                                            : null
export const UNITS                = ( ext_API, PID, LNG='da' )              => PID ? `${PRJ(ext_API, PID)}/units?Language=${LNG}`                                : null
export const VERSION              = ( ext_API, PID )                        => PID ? `${PRJ(ext_API, PID)}/unitsdataversion`                                     : null
export const LOG                  = ( ext_API, PID )                        => PID ? `${PRJ(ext_API, PID)}/logs`                                                 : null
export const BENEFIT              = ( PID, ID, INC, SITE )         => PID ? `${PRJ(API1, PID)}/units/${ID}${BF1}${INC}${BF2}${SITE}`                 : null
export const UNIT                 = ( ext_API, PID, ID, LNG='da' )          => PID ? `${PRJ(ext_API, PID)}/units/${ID}?Language=${LNG}`                          : null
export const COMPARE              = ( ext_API, PID, LIST_OF_IDS, LNG='da' ) => PID ? `${PRJ(ext_API, PID)}/unitscompare?UnitIDs=${LIST_OF_IDS}&Language=${LNG}`  : null
export const PAGE                 = ( ext_API, PID, PAGEID, LNG='da' )      => PID ? `${PRJ(ext_API, PID)}/pages/${PAGEID}?Language=${LNG}`                      : null
export const DOWNLOADS            = ( ext_API, PID, LNG='da' )              => PID ? `${PRJ(ext_API, PID)}/downloads?Language=${LNG}`                            : null
export const EMAIL                = () =>  `${API}${EMAIL_API}`
export const MOVEINCALCULATIONS   = ( ext_API, PID , ID )=> PID ? `${PRJ(ext_API, PID)}/units/${ID}/calculation/movein` : null
export const DEPOSITECALCULATIONS = ( ext_API, PID , ID )=> PID ? `${PRJ(ext_API, PID)}/units/${ID}/calculation/deposit` : null
export const PREPAIDCALCULATIONS = ( ext_API, PID , ID)=> PID ? `${PRJ(ext_API, PID)}/units/${ID}/calculation/prepaid` : null

  // RENTAL/SALES PID
export const RS_PID = {
    "23": "31"
}