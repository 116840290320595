import { Box } from "@mui/material";
import { StyledContainer, StyledGridContainer } from "./Gallery.style";
import { GallarySwiper } from "./Gallary.swiper";
import { useBreak } from "hooks/useBreak";

export const MultiProjectImages = ({ images, setActiveGlry, activeGlry }) => {
    const isDesktop = useBreak("md_up");

    return <StyledContainer style={{ padding: isDesktop ? "20px 96px 40px" : "16px 0 0 16px" }}>
            <StyledGridContainer>
                        <p className="h3">Vælg ejendom</p>
                        <Box sx={{ mt: 3 }}>
                                <GallarySwiper activeGlry={activeGlry} images={images} setActiveGlry={setActiveGlry} />
                        </Box>
            </StyledGridContainer>
         </StyledContainer>
}