  // Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

  // Import Swiper styles
import 'swiper/css';
import { ImgWrapper } from 'components/ImgContainer/ImgWrapper';
import { AllImagePack } from './gallary.allImagePack';
import React, { useState } from 'react';
import { Controller, FreeMode, Keyboard, Pagination, Thumbs } from 'swiper';
import { Box, styled } from '@mui/material';
import { Icon } from 'components/Icons';
import { useBreak } from 'hooks/useBreak';
import { Transition } from './Gallery.style';

export const GallarySwiper = ({ images, setActiveGlry, activeGlry  }) => {
  let totalImgCount = 0
  const isDesktop = useBreak("md_up");

  // Swiper variables
  const [swiper, setSwiper] = useState(null);
  const [swiperThumbs, setSwiperThumbs] = useState(null);

  // Swiper function
  const handlePrev = () => {
    swiper.slidePrev();
  };

  const handleNext = () => {
    swiper.slideNext();
  };

  const handleClickThumb = (ind) => {
    swiper.slideTo(ind);
  };
  // Swiper function
  
  // Gallary handle functions
  const handleGallaryImages = ({ PID, PIN, PIDINDEX }) => {
    setActiveGlry({
      glryPin: PIN,
      glryPid: PID, 
      glryPidIndex: PIDINDEX
    })
  }

  return (
    <Box sx={{ position: "relative", 
        // "& .swiper": {overflow:"visible"}, 
    "& .swiper-slide": { width: "auto !important", height: isDesktop ? "250px" : "130px", paddingBottom: "50px", marginTop: "10px" }, 
    "& .swiper .swiper-pagination .swiper-pagination-bullet": { background: clr.primary}}}>
      <Swiper
        pagination={isDesktop ? true : false}
          onSwiper={setSwiper}
        //   spaceBetween  = {16}
          slidesPerView = {isDesktop ? 4 : 'auto'}
        //   onSlideChange = {() => console.log('slide change')}
          modules={[FreeMode, Keyboard, Thumbs, Controller, Pagination]}
          >
            <SwiperSlide style={{ transform: "unset" }}>
                <AllImagePack allImages={images} setActiveGlry={setActiveGlry} activeGlry={activeGlry} />
            </SwiperSlide>

            {
                images.map((image, index) => {
                    const keys   = Object.keys(image ?? {})
                    const i_keys = keys.length && Object.keys(image[keys[0]] ?? {})
                    
                        return <React.Fragment key={index}>
                          {
                            Boolean(i_keys.length) && i_keys.map((i_k, i) => {
                                let img_url = image[keys[0]]?.[i_k]?.Data?.ListOfPhotos?.find((au) => au.Description === "Forsidebillede")

                                let isSelected = false

                                if(activeGlry?.glryPidIndex === i_k && activeGlry?.glryPid === keys[0] && activeGlry?.glryPin === index) {
                                    isSelected = true
                                }
                                
                                if(i_keys.length === 1 && !i) totalImgCount += 1
                                else if(i_keys.length !== 1 && i) totalImgCount += 1

                              // if property have only different id like c6, c7
                                if (i_keys.length === 1 && !i) return 
                                <SwiperSlide onClick={ ()=> handleGallaryImages({ PID: keys[0], PIN: index, PIDINDEX: i_k }) } style={{ cursor: "pointer", 
                                // height: "200px", width: isDesktop ? "auto" : "250px"
                                 }} key  = {`${index}+${i}`}>
                                    <Transition isSelected={isSelected}>
                                        {/* <ImageContainer image={image[keys[0]][i]}  /> */}
                                        {
                                             Boolean(img_url?.Url) &&  <ImageContainer image={img_url?.Url}  />
                                        }
                                    </Transition>
                                </SwiperSlide>
        
                                // if property have only type like sales and rent
                                if(i_keys.length !== 1 && i_keys.length > 1 && i) return <SwiperSlide onClick={ ()=> handleGallaryImages({ PID: keys[0], PIN: index, PIDINDEX: i_k }) } style={{ cursor: "pointer", 
                                // height: "200px", width: isDesktop ? "auto" : "250px" 
                                }} key = {`${index}+${i}`} > 
                                <Transition isSelected={isSelected}>
                                    {/* <ImageContainer image={image[keys[0]][i]} /> */}
                                    {
                                        Boolean(img_url?.Url) && <ImageContainer image={img_url}  />
                                    }
                                    <p className={ isSelected ? 'h4' : 'paragraph1-light' }>
                                        { i === 1 ? "Ejerboliger" : 
                                        i === 2 ? "Lejeboliger" :
                                        "" 
                                        }
                                    </p>
                                </Transition>
                                </SwiperSlide>
                                  
                            })
                          }
                        </React.Fragment>
                })
            }
        </Swiper>
      { isDesktop && totalImgCount > 3 &&
        <Box> 
          <Arrow left onClick={handlePrev}>
              <div
                style={{
                  borderRadius: "6px",
                  backgroundColor: clr.primary,
                  display: "flex",
                  height: "32px",
                  width: "32px",
                  padding: "8px 0px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon icon="arrow-left" sx={{ color: "#fff", fontSize: 16 }} />
              </div>
            </Arrow>
            <Arrow right onClick={handleNext}>
              <div
                style={{
                  borderRadius: "6px",
                  backgroundColor: clr.primary,
                  display: "flex",
                  height: "32px",
                  width: "32px",
                  padding: "8px 0px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon icon="arrow-right" sx={{ color: "#fff", fontSize: 16 }} />
              </div>
            </Arrow>
        </Box>
      }
    </Box>
  );
};


const ImageContainer = ({ image }) => {
    return (
        <>
            <ImgWrapper img={image?.Url} style={{ width : "100%",
            height: "auto", borderRadius: '6px' }} alt={"gallary images"}/>
        </>
    )
}

// STYLES

const Arrow = styled(Box)(({ left, right, UnitDrawer }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  padding: "8px 0px",
  top: "50%",
  borderRadius: left ? "0px 6px 6px 0px" : "6px 0px 0px 6px",
  transform: "translateY(-236%)",
  left: left ? "-48px" : "unset",
  right: right ? "-48px" : "unset",
  width: "32px",
  height: "32px",
  zIndex: 1000,
  cursor: "pointer",
  "& > span": {
    height: 20,
  },
}));


let a =  [
  {
      "23": {
          "0": {
              "TypeID": 11700,
              "TypeName": "Gallery",
              "Data": {
                  "NoOfPhotos": 24,
                  "ListOfPhotos": [
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      }
                  ],
                  "Floorplan": null,
                  "Floorplans": [],
                  "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
              },
              "Tags": [
                  "Ejendom",
                  "Interiør",
                  "Eksteriør"
              ]
          },
          "1": {
              "TypeID": 11700,
              "TypeName": "Gallery",
              "Data": {
                  "NoOfPhotos": 9,
                  "ListOfPhotos": [
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      }
                  ],
                  "Floorplan": null,
                  "Floorplans": [],
                  "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
              },
              "Tags": [
                  "Ejendom",
                  "Interiør"
              ]
          },
          "2": {
              "TypeID": 11700,
              "TypeName": "Gallery",
              "Data": {
                  "NoOfPhotos": 15,
                  "ListOfPhotos": [
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      }
                  ],
                  "Floorplan": null,
                  "Floorplans": [],
                  "VideoDefaultThumbnailUrl": "https://9rzh9nu7e8ix1fyfhzjc8odb5xgh4tfp.pratik.estatetool.net/media/thumbnails/video-player.png"
              },
              "Tags": [
                  "Eksteriør",
                  "Interiør"
              ]
          }
      }
  },
  {
      "23": {
          "0": {
              "TypeID": 11700,
              "TypeName": "Gallery",
              "Data": {
                  "NoOfPhotos": 24,
                  "ListOfPhotos": [
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      }
                  ],
                  "Floorplan": null,
                  "Floorplans": [],
                  "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
              },
              "Tags": [
                  "Ejendom",
                  "Interiør",
                  "Eksteriør"
              ]
          },
          "1": {
              "TypeID": 11700,
              "TypeName": "Gallery",
              "Data": {
                  "NoOfPhotos": 9,
                  "ListOfPhotos": [
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      }
                  ],
                  "Floorplan": null,
                  "Floorplans": [],
                  "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
              },
              "Tags": [
                  "Ejendom",
                  "Interiør"
              ]
          },
          "2": {
              "TypeID": 11700,
              "TypeName": "Gallery",
              "Data": {
                  "NoOfPhotos": 15,
                  "ListOfPhotos": [
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      }
                  ],
                  "Floorplan": null,
                  "Floorplans": [],
                  "VideoDefaultThumbnailUrl": "https://9rzh9nu7e8ix1fyfhzjc8odb5xgh4tfp.pratik.estatetool.net/media/thumbnails/video-player.png"
              },
              "Tags": [
                  "Eksteriør",
                  "Interiør"
              ]
          }
      }
  },
  {
      "23": {
          "0": {
              "TypeID": 11700,
              "TypeName": "Gallery",
              "Data": {
                  "NoOfPhotos": 24,
                  "ListOfPhotos": [
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      }
                  ],
                  "Floorplan": null,
                  "Floorplans": [],
                  "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
              },
              "Tags": [
                  "Ejendom",
                  "Interiør",
                  "Eksteriør"
              ]
          },
          "1": {
              "TypeID": 11700,
              "TypeName": "Gallery",
              "Data": {
                  "NoOfPhotos": 9,
                  "ListOfPhotos": [
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      }
                  ],
                  "Floorplan": null,
                  "Floorplans": [],
                  "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
              },
              "Tags": [
                  "Ejendom",
                  "Interiør"
              ]
          },
          "2": {
              "TypeID": 11700,
              "TypeName": "Gallery",
              "Data": {
                  "NoOfPhotos": 15,
                  "ListOfPhotos": [
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      }
                  ],
                  "Floorplan": null,
                  "Floorplans": [],
                  "VideoDefaultThumbnailUrl": "https://9rzh9nu7e8ix1fyfhzjc8odb5xgh4tfp.pratik.estatetool.net/media/thumbnails/video-player.png"
              },
              "Tags": [
                  "Eksteriør",
                  "Interiør"
              ]
          }
      }
  },
  {
      "23": {
          "0": {
              "TypeID": 11700,
              "TypeName": "Gallery",
              "Data": {
                  "NoOfPhotos": 24,
                  "ListOfPhotos": [
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      }
                  ],
                  "Floorplan": null,
                  "Floorplans": [],
                  "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
              },
              "Tags": [
                  "Ejendom",
                  "Interiør",
                  "Eksteriør"
              ]
          },
          "1": {
              "TypeID": 11700,
              "TypeName": "Gallery",
              "Data": {
                  "NoOfPhotos": 9,
                  "ListOfPhotos": [
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Ejendom",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                          "Tags": [
                              "Ejendom"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                          "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      }
                  ],
                  "Floorplan": null,
                  "Floorplans": [],
                  "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
              },
              "Tags": [
                  "Ejendom",
                  "Interiør"
              ]
          },
          "2": {
              "TypeID": 11700,
              "TypeName": "Gallery",
              "Data": {
                  "NoOfPhotos": 15,
                  "ListOfPhotos": [
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Eksteriør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                          "Tags": [
                              "Eksteriør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      },
                      {
                          "Description": "Interiør",
                          "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                          "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                          "Tags": [
                              "Interiør"
                          ],
                          "IsFloorplan": false,
                          "IsVideo": false,
                          "VideoAutoplay": false,
                          "VideoLoop": false,
                          "VideoWidth": null,
                          "VideoHeight": null
                      }
                  ],
                  "Floorplan": null,
                  "Floorplans": [],
                  "VideoDefaultThumbnailUrl": "https://9rzh9nu7e8ix1fyfhzjc8odb5xgh4tfp.pratik.estatetool.net/media/thumbnails/video-player.png"
              },
              "Tags": [
                  "Eksteriør",
                  "Interiør"
              ]
          }
      }
  }
]
