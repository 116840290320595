import { Box } from "@mui/material"
import { ImgWrapper } from "components/ImgContainer/ImgWrapper"
import { transform } from "framer-motion"
import { useBreak } from "hooks/useBreak"
import React from "react"

export const AllImagePack = ({ allImages, setActiveGlry, activeGlry }) => {
    const isDesktop = useBreak("md_up");

   let totalImgCount = 0
    return (
        <>
        <div>
        <Box onClick={()=> setActiveGlry({})} sx={{  display: "flex", flexDirection: "column", cursor: "pointer",
         width:  isDesktop ? "300px" : "200px",  
         height: isDesktop ? "210px" : "150px", //"180px" : "120px",
         padding: "0 8px",
        //     transform: Object.keys(activeGlry || {}).length ? "scale(0.9)" : "scale(1)", 
        transition: "transform 0.3s linear", 
        overflow: "hidden",
        '&:hover': {
            transform: "scale(calc(308/300))"
          }, 
          }}>
            <Box sx={{ display: "flex", gap: '8px', flexWrap: "wrap",  justifyContent: "center", width: "100%", height: "calc(100% - 30px)" }}>
            {
            allImages.map((image, index) => {
                const keys   = Object.keys(image ?? {})
                const i_keys = keys.length && Object.keys( image[keys[0]] ?? {})
                return <React.Fragment key={index}>
                    {
                        Boolean(i_keys.length) && i_keys.map((i_k, i) => {
                            let img_url = image[keys[0]]?.[i_k]?.Data?.ListOfPhotos?.find((au) => au.Description === "Forsidebillede")
                            
                            if(i_keys.length === 1 && !i) totalImgCount += 1
                            else if(i_keys.length !== 1 && i) totalImgCount += 1
                                return <React.Fragment key={`${index}${i}+${i_k}`}>
                                    {
                                        totalImgCount < 4 ? 
                                        <>
                                        
                                        {
                                            (i_keys.length === 1 && !i) ? Boolean(img_url?.Url) && <ImgWrapper img={img_url?.Url} style={{ width : "calc(50% - 4px)", height: "calc(50% - 4px)", borderRadius: '6px' }} alt={"gallary images"}/> : <></>
                                        } 
                                        {
                                            (i_keys.length !== 1 && i) ? Boolean(img_url?.Url) && <ImgWrapper img={img_url?.Url} style={{ width : "calc(50% - 4px)", height: "calc(50% - 4px)", borderRadius: '6px' }} alt={"gallary images"}/> : <></>
                                        }
                                            {
                                            /* {
                                            (i_keys.length === 1 && !i) ? <ImgWrapper img={image[keys[0]][i_k]?.Data?.ListOfPhotos?.[0]?.Url} style={{ width : "calc(50% - 4px)", height: "calc(50% - 4px)", borderRadius: '6px' }} alt={"gallary images"}/> : <></>
                                            } 
                                            {
                                                (i_keys.length !== 1 && i) ? <ImgWrapper img={image[keys[0]][i_k]?.Data?.ListOfPhotos?.[0]?.Url} style={{ width : "calc(50% - 4px)", height: "calc(50% - 4px)", borderRadius: '6px' }} alt={"gallary images"}/> : <></>
                                            } */}
                                        </>
                                        : ""  
                                    }
                                </React.Fragment>
                            })
                    }
                </React.Fragment>
            })
        }
          {totalImgCount > 3 && 
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width : "calc(50% - 4px)", gap: 1 }}>
                  <span className="h1">+</span> <span className="h1">{totalImgCount - 3}</span>
               </Box>
          }
            </Box>
   
            <p className={ Object.keys(activeGlry || {}).length ? 'paragraph1-light' : 'h4-medium' } style={{ marginTop: "8px" }}>
                Alle ejendomme
            </p>
        </Box>
        </div>
        </>
    )
  }


  let a =  [
    {
        "23": {
            "0": {
                "TypeID": 11700,
                "TypeName": "Gallery",
                "Data": {
                    "NoOfPhotos": 24,
                    "ListOfPhotos": [
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        }
                    ],
                    "Floorplan": null,
                    "Floorplans": [],
                    "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
                },
                "Tags": [
                    "Ejendom",
                    "Interiør",
                    "Eksteriør"
                ]
            },
            "1": {
                "TypeID": 11700,
                "TypeName": "Gallery",
                "Data": {
                    "NoOfPhotos": 9,
                    "ListOfPhotos": [
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        }
                    ],
                    "Floorplan": null,
                    "Floorplans": [],
                    "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
                },
                "Tags": [
                    "Ejendom",
                    "Interiør"
                ]
            },
            "2": {
                "TypeID": 11700,
                "TypeName": "Gallery",
                "Data": {
                    "NoOfPhotos": 15,
                    "ListOfPhotos": [
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        }
                    ],
                    "Floorplan": null,
                    "Floorplans": [],
                    "VideoDefaultThumbnailUrl": "https://9rzh9nu7e8ix1fyfhzjc8odb5xgh4tfp.pratik.estatetool.net/media/thumbnails/video-player.png"
                },
                "Tags": [
                    "Eksteriør",
                    "Interiør"
                ]
            }
        }
    },
    {
        "23": {
            "0": {
                "TypeID": 11700,
                "TypeName": "Gallery",
                "Data": {
                    "NoOfPhotos": 24,
                    "ListOfPhotos": [
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        }
                    ],
                    "Floorplan": null,
                    "Floorplans": [],
                    "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
                },
                "Tags": [
                    "Ejendom",
                    "Interiør",
                    "Eksteriør"
                ]
            },
            "1": {
                "TypeID": 11700,
                "TypeName": "Gallery",
                "Data": {
                    "NoOfPhotos": 9,
                    "ListOfPhotos": [
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        }
                    ],
                    "Floorplan": null,
                    "Floorplans": [],
                    "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
                },
                "Tags": [
                    "Ejendom",
                    "Interiør"
                ]
            },
            "2": {
                "TypeID": 11700,
                "TypeName": "Gallery",
                "Data": {
                    "NoOfPhotos": 15,
                    "ListOfPhotos": [
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        }
                    ],
                    "Floorplan": null,
                    "Floorplans": [],
                    "VideoDefaultThumbnailUrl": "https://9rzh9nu7e8ix1fyfhzjc8odb5xgh4tfp.pratik.estatetool.net/media/thumbnails/video-player.png"
                },
                "Tags": [
                    "Eksteriør",
                    "Interiør"
                ]
            }
        }
    },
    {
        "23": {
            "0": {
                "TypeID": 11700,
                "TypeName": "Gallery",
                "Data": {
                    "NoOfPhotos": 24,
                    "ListOfPhotos": [
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        }
                    ],
                    "Floorplan": null,
                    "Floorplans": [],
                    "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
                },
                "Tags": [
                    "Ejendom",
                    "Interiør",
                    "Eksteriør"
                ]
            },
            "1": {
                "TypeID": 11700,
                "TypeName": "Gallery",
                "Data": {
                    "NoOfPhotos": 9,
                    "ListOfPhotos": [
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        }
                    ],
                    "Floorplan": null,
                    "Floorplans": [],
                    "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
                },
                "Tags": [
                    "Ejendom",
                    "Interiør"
                ]
            },
            "2": {
                "TypeID": 11700,
                "TypeName": "Gallery",
                "Data": {
                    "NoOfPhotos": 15,
                    "ListOfPhotos": [
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        }
                    ],
                    "Floorplan": null,
                    "Floorplans": [],
                    "VideoDefaultThumbnailUrl": "https://9rzh9nu7e8ix1fyfhzjc8odb5xgh4tfp.pratik.estatetool.net/media/thumbnails/video-player.png"
                },
                "Tags": [
                    "Eksteriør",
                    "Interiør"
                ]
            }
        }
    },
    {
        "23": {
            "0": {
                "TypeID": 11700,
                "TypeName": "Gallery",
                "Data": {
                    "NoOfPhotos": 24,
                    "ListOfPhotos": [
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        }
                    ],
                    "Floorplan": null,
                    "Floorplans": [],
                    "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
                },
                "Tags": [
                    "Ejendom",
                    "Interiør",
                    "Eksteriør"
                ]
            },
            "1": {
                "TypeID": 11700,
                "TypeName": "Gallery",
                "Data": {
                    "NoOfPhotos": 9,
                    "ListOfPhotos": [
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/fe709a17abd8ba6ba6f30fcf7d7abcde_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c73887328c64abc974d02805cc9d1adc_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1b092e888caf5aa28f60c2a4fde82808_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/9d4894c26ada64be096a4b471ffea935_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Ejendom",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/329e474591433579cb497e9e97a95a97_A.jpg",
                            "Tags": [
                                "Ejendom"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/e4f0d722112897925c24a797a8a53749_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/11af050470dc9e27c7558c144a8200d6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/1e108930838ee3b10657642dafe99414_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_D.jpg",
                            "UrlThumbnail": "https://npvejendom.b-cdn.net/media/ejendom/c1cb19ec871d3f08373124f09c5272fc_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        }
                    ],
                    "Floorplan": null,
                    "Floorplans": [],
                    "VideoDefaultThumbnailUrl": "https://eidodata.gammelhavn.nu/media/thumbnails/video-player.png"
                },
                "Tags": [
                    "Ejendom",
                    "Interiør"
                ]
            },
            "2": {
                "TypeID": 11700,
                "TypeName": "Gallery",
                "Data": {
                    "NoOfPhotos": 15,
                    "ListOfPhotos": [
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a3e193caa30d190ba3fd60c8a1abbcf0_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Eksteriør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/e6bd6b84f6da7dcdd6e2afdb39559299_A.jpg",
                            "Tags": [
                                "Eksteriør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/d3b6d415d866f41fc21d28350afc0dcd_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/a4e795ad6cdf7cab66fc3840ad68d540_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/4e82746bfa0a33bcfecf5fc12ec571e7_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/50bfdd8758f804bcd738e81dd69717fa_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/138574fe194cfe08e3c90a79f15a2d68_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/34a99ea8fcb64a4b4209f05f6d0c17b3_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ef978ba1766fa072d1bd4370d5618ab6_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/846d2583982cf318637a6f3f8f7a38e5_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/ff955db3493f72853bac9c97efa970da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/52062755215c1094f42db78d40e62580_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/8a0d890477d510ebf9f12c2bb8ca2a25_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/da9a53a7198c8e69278f4f9fe72042da_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        },
                        {
                            "Description": "Interiør",
                            "Url": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_D.jpg",
                            "UrlThumbnail": "https://homeejendom.b-cdn.net/media/ejendom/520584126c674a68d9c0072997b71569_A.jpg",
                            "Tags": [
                                "Interiør"
                            ],
                            "IsFloorplan": false,
                            "IsVideo": false,
                            "VideoAutoplay": false,
                            "VideoLoop": false,
                            "VideoWidth": null,
                            "VideoHeight": null
                        }
                    ],
                    "Floorplan": null,
                    "Floorplans": [],
                    "VideoDefaultThumbnailUrl": "https://9rzh9nu7e8ix1fyfhzjc8odb5xgh4tfp.pratik.estatetool.net/media/thumbnails/video-player.png"
                },
                "Tags": [
                    "Eksteriør",
                    "Interiør"
                ]
            }
        }
    }
  ]